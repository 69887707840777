import React, { useContext, useEffect, useMemo } from "react";

import qs from "query-string";
import { useFormContext } from "react-hook-form";

import { useMutableData } from "API/useData";
import { API_URL } from "Views/Bills/V2/constants";
import { getResponsePayload } from "Views/Bills/V2/utilities";
import { RecipientFormInputs } from "Views/Bills/V2/Recipient/types";

import Select from "../Select";
import { VendorDynamicFieldNames } from "Views/Bills/V2/components/FormGenerator/type";
import { BillContext } from "Views/Bills/V2/context/BillContext";

// TODO: create interface for the props
const RecipientCurrencySelect = (props: any) => {
  const { value, onChange } = props;
  const { ocrData } = useContext(BillContext);
  const {
    watch,
    formState: { dirtyFields },
  } = useFormContext<RecipientFormInputs>();
  const countryCode = watch(VendorDynamicFieldNames.countryCode);

  const currencyListURL = qs.stringifyUrl({
    url: API_URL.currencyList,
    query: {
      filterBy: "org",
      recipientCountry: countryCode?.substring(0, 2),
    },
  });

  const { data } = useMutableData(countryCode ? currencyListURL : null);
  const options = useMemo(
    () =>
      (getResponsePayload(data) as any)?.result?.map((item) => ({
        value: item.CurrencyCode,
        label: item.CurrencyCode,
      })) || [],
    [data]
  );

  // initial value for currency base on selected country
  useEffect(() => {
    if (options.length) {
      const ocrCurrencyCode = ocrData?.recipientData?.currencyCode;
      const isDirtyCountryCode = dirtyFields[VendorDynamicFieldNames.countryCode];

      const option = options.find((item) => {
        if (ocrCurrencyCode && !isDirtyCountryCode) {
          return item.value === ocrCurrencyCode;
        } else if (value && !isDirtyCountryCode) {
          return item.value === value;
        }

        return item.value.substring(0, 2) === countryCode;
      });
      onChange(option?.value || options[0].value);
    }
  }, [countryCode, options, ocrData, dirtyFields]);

  return <Select {...props} options={options} />;
};

export default RecipientCurrencySelect;
