import "Views/SecurityHub/GoogleAuthenticator/ActivateGA/GaActivationInfo/GaActivationInfo.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Input } from "antd";
import Button from "Modules/button";
import Loader from "Views/State/Loading/LoaderIcon";
import {
  fetchGaQrCode,
  verifyGaAuthenticationCode,
} from "Redux/Actions/SecurityHub/GoogleAuthenticator/ActivationActions";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { personalMenu } from "Views/Settings/config";

const GaActivationInfo = () => {
  const {
    fetchGaQrCodeLoading,
    fetchGaQrCodeData,
    fetchGaQrCodeError,
    verifyGaAuthenticationKeyLoading,
    verifyGaAuthenticationKeyData,
    verifyGaAuthenticationKeyError,
  } = useSelector((state) => state.gaActivationReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchGaQrCode());
  }, [dispatch]);

  useEffect(() => {
    if (verifyGaAuthenticationKeyError) {
      form.setFields([
        {
          name: "authenticationCode",
          errors: [verifyGaAuthenticationKeyData?.errorMessage || "Something went wrong. please try again later"],
        },
      ]);
    } else if (verifyGaAuthenticationKeyData?.status === HTTP_STATUS_CODE.OK) {
      history.push(personalMenu[0].path, {
        message: "Google Authenticator is now active.",
      });
    }
  }, [verifyGaAuthenticationKeyData]);

  const getTitle = (title) => (
    <legend className="activation-info__fieldset--legend">
      <p className="activation-info__fieldset--title">{title}</p>
    </legend>
  );

  const getImageSrc = () => `data:image/png;base64,${fetchGaQrCodeData.data.qr_image}`;

  const getQRandSetupCode = () => {
    switch (true) {
      case fetchGaQrCodeLoading:
        return <Loader />;
      case !!fetchGaQrCodeData?.data?.qr_image:
        return (
          <>
            <img className="activation-info__qr-code" src={getImageSrc()} alt="qr code" />
            <p>
              If you can't scan the barcode properly, enter this setup key.
              <br />
              {fetchGaQrCodeData?.data?.secret_key || ""}
            </p>
          </>
        );
      case fetchGaQrCodeError:
        return (
          <div className="activation-info__error-message">
            {fetchGaQrCodeData?.errorMessage || "Unable to generate a QR code. Please try again later"}
          </div>
        );
      default:
        return null;
    }
  };

  const onFinishHandler = (formData) => {
    const secretKey = fetchGaQrCodeData?.data?.secret_key || "";
    const otp = formData?.authenticationCode || "";
    if (secretKey && otp?.length === 6) {
      const payload = {
        secret_auth_key: fetchGaQrCodeData?.data?.secret_key,
        otp: formData.authenticationCode,
      };
      dispatch(verifyGaAuthenticationCode(payload));
    }
  };

  return (
    <div className="section section--white section--full-height">
      <div className="section__header section__header--black activation-info__header">Google Authenticator</div>
      <Form
        form={form}
        labelAlign="left"
        className="activation-info__form"
        colon={false}
        onFinish={onFinishHandler}
        requiredMark={false}
      >
        <fieldset className="activation-info__fieldset">
          {getTitle("1. Scan the QR Code below on your Google Authenticator Apps.")}
          <legend className="activation-info__fieldset--qr-code-container">{getQRandSetupCode()}</legend>
        </fieldset>
        <fieldset className="activation-info__fieldset">
          {getTitle("2. Enter 6-digit authentication code generated by the Google Authenticator Apps.")}
          <legend>
            <Form.Item
              label="Authentication code"
              name="authenticationCode"
              rules={[
                { required: true, message: "Please input the authentication code" },
                { max: 6, message: "Please input the correct authentication code" },
              ]}
            >
              <Input placeholder="Type 6-digit code" bordered max={6} disabled={fetchGaQrCodeError} />
            </Form.Item>
          </legend>
        </fieldset>
        <Row justify="center" align="middle">
          <Col className="activation-info__action-btn">
            <Button
              rounded
              text="Activate Google Authenticator"
              disabled={fetchGaQrCodeLoading || fetchGaQrCodeError}
              loading={verifyGaAuthenticationKeyLoading}
              onClick={() => form.submit()}
              size="xl"
              showOnlyLoaderIconWhenLoading
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GaActivationInfo;
