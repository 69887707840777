import { useCallback, useEffect, useMemo } from "react";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";
import { REIMBURSEMENT_TRANSACTION_PAGE } from "Permission/Actions";

import { COMMON_PATHS } from "Route/Common/paths";

import { PRODUCT_NAME } from "Redux/ModularProduct";

import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import {
  PAGE_TABS,
  REIMBURSEMENT_VIEWS,
  REIMBURSEMENT_QUERY_PARAMS,
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_SETTLEMENT_OPTIONS,
  REIMBURSEMENT_MODAL_OPTIONS,
} from "Views/Reimbursement/Constants";
import { IUseTabRedirection } from "Views/Reimbursement/@types";
import { trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";
import usePayoutValidation from "Views/Reimbursement/hooks/usePayoutValidation";
import useConfig from "Views/Reimbursement/hooks/useConfig";

const permissionParam = [
  { object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE, action: REIMBURSEMENT_TRANSACTION_PAGE.VIEW_COMPANY_TAB },
  { object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE, action: REIMBURSEMENT_TRANSACTION_PAGE.VIEW_SETTLEMENT },
];

export const useAccessControl = (tabKey: string) => {
  const history = useHistory();

  const isTabFound = useMemo(() => PAGE_TABS.find((tab) => tab.tabKey === tabKey), [tabKey]);

  const [isCrConfigEnabled, loadingCrConfig] = useCheckOrgConfigs(PRODUCT_NAME.CASH_REIMBURSEMENT, true) as [
    boolean,
    boolean
  ];

  const { setPayoutDetails, loadingPayoutDetails } = usePayoutValidation();
  const { data: configData, showSkeleton: loadingConfig } = useConfig();

  const [permissions, loadingPermission] = usePermissionCheck(permissionParam, true) as [boolean[], boolean];

  const isPaymentEnabled = useMemo(() => configData.isPaymentEnabled || false, [configData]);

  const isAllowedCompanyTab = useMemo(() => permissions[0] || false, [permissions]);
  const isAllowedSettlementView = useMemo(() => permissions[1] || false, [permissions]);

  const redirectTo = useCallback(
    (pathname: string) => {
      history.replace({
        pathname,
        search: qs.stringify({
          [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
        }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const verifyPayoutDetails = useCallback(
    (url: string) => {
      const modal = `${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.METHOD_VALIDATION}`;

      if (setPayoutDetails && isPaymentEnabled) {
        redirectTo(`${url}/${modal}`);
      } else {
        redirectTo(url);
      }
    },
    [redirectTo, setPayoutDetails, isPaymentEnabled]
  );

  const handleTabRedirections = useCallback(() => {
    // Define URLs for different tabs and modals
    const personal = `${REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link}`;
    const company = `${REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS.link}`;
    const approvedSettlements = `${company}/${REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS}/${REIMBURSEMENT_SETTLEMENT_OPTIONS[0].id}`;

    // Redirect based on permissions and configurations
    if (isAllowedCompanyTab) {
      // Redirect to company tab
      if (isAllowedSettlementView) {
        // If settlement view is allowed, redirect to approved settlements with or without modal
        verifyPayoutDetails(approvedSettlements);
      } else {
        // If settlement view is not allowed, redirect to company tab with or without modal
        verifyPayoutDetails(company);
      }
    } else {
      // Redirect to personal tab with or without modal
      verifyPayoutDetails(personal);
    }
  }, [isAllowedCompanyTab, isAllowedSettlementView, verifyPayoutDetails]);

  // Redirection Logic
  useEffect(() => {
    // Check if all loading states are false
    if (!loadingPermission && !loadingCrConfig && !loadingPayoutDetails && !loadingConfig) {
      // Check if CR config is enabled
      if (isCrConfigEnabled) {
        // If CR config is enabled and tab is not found
        if (!isTabFound) {
          handleTabRedirections();
        }
      } else {
        // If CR config is not enabled, redirect to 404 page
        history.replace(COMMON_PATHS.PAGE_NOT_FOUND);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTabFound,
    loadingPermission,
    loadingCrConfig,
    loadingConfig,
    loadingPayoutDetails,
    isCrConfigEnabled,
    handleTabRedirections,
  ]);

  return {
    isAllowedCompanyTab,
    isAllowedSettlementView,
    loading: loadingPermission || loadingCrConfig || loadingPayoutDetails || loadingConfig,
  };
};

const useTabRedirection = (tabKey: string): IUseTabRedirection => {
  const history = useHistory();
  const location = useLocation();

  const { isAllowedCompanyTab, isAllowedSettlementView, loading } = useAccessControl(tabKey);

  const tabNotFound = useMemo(
    () => PAGE_TABS.find((tab) => location.pathname.includes(tab.tabKey)) === undefined,
    [location.pathname]
  );

  const tabAnalytics = useCallback(() => {
    const currentTab = PAGE_TABS.find((tab) => tab.tabKey === tabKey);
    Boolean(currentTab) &&
      trackReimbursementAnalytics({
        eventName: currentTab.eventSource,
        eventSource: "Tab",
        eventSubSource: "Loaded",
      });
  }, [tabKey]);

  // Page Analytics
  useEffect(() => {
    tabAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTab = (tabKey: string) => {
    let url: string;

    switch (true) {
      case tabKey === REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.tabKey:
        url = REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link;
        break;
      case tabKey === REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS.tabKey &&
        isAllowedCompanyTab &&
        isAllowedSettlementView:
        url = `${REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS.link}/${REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS}/${REIMBURSEMENT_SETTLEMENT_OPTIONS[0].id}`;
        break;
      case tabKey === REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS.tabKey && !isAllowedSettlementView:
      default:
        url = REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS.link;
        break;
    }

    history.push({
      pathname: url,
      search: qs.stringify({
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });

    tabAnalytics();
  };

  const tabs = PAGE_TABS.filter((tab) => {
    if (!isAllowedCompanyTab && !loading && tab.tabKey === REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS.tabKey) {
      return null;
    } else {
      return tab;
    }
  });

  return {
    tabs,
    onChangeTabHandler: handleChangeTab,
    loading: loading || tabNotFound,
  };
};

export default useTabRedirection;
