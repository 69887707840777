import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { Modal, Typography } from "@spenmo/splice";
import { useHistory } from "react-router-dom";

import ErrorHandlerProvider from "./ErrorHandlerContext";

import { API_URL, PAYMENT_RUN_URL } from "Views/Bills/V2/constants";

import { DynamicFormIdentifier } from "Views/Bills/V2/hooks/useRecipientFormFields/types";
import { OCRData } from "Views/Bills/V2/BillForm/type";
import { useMutableData } from "API/useData";
import { CASBIN_PAGES } from "Permission/Pages";
import { PAYMENT_RUN_BILL_PAY_PAGE } from "Permission/Actions";
import usePermissionCheck from "Permission/usePermissionCheck";
import { PermissionCheckResultWithLoading } from "Permission/types";

interface BillContextType {
  isOpenRecipientSidePanel: boolean;
  setIsOpenRecipientSidePanel: (value: boolean) => void;
  dynamicFieldsIdentifier: Record<string, DynamicFormIdentifier>;
  setDynamicFieldsIdentifier: (value: Record<string, DynamicFormIdentifier>) => void;
  // for edit recipient
  recipientSelectedID?: number;
  setRecipientSelectedID: (id: number) => void;
  ocrData?: OCRData;
  setOCRData(value: OCRData): void;
  withOCRData?: boolean;
  setWithOCRData(value: boolean): void;
  openBudgetOverlimitModal(overlimitBillsCount: number, overlimitBudgetNames: string[]): void;
  permissionAndSettings: PermissionAndSettings;
}

interface PermissionAndSettings {
  settingLoading: boolean;
  settings: boolean;
  viewAllowed: boolean;
  setupAllowed: boolean;
  permissionLoading: boolean;
}

// TO DO: is it still needed?
export const BillContext = createContext<Partial<BillContextType>>({
  isOpenRecipientSidePanel: false,
});

export const useBill = () => {
  const form = useContext(BillContext);

  if (form === null) {
    throw Error("useBill requires BillContext provider to be used higher in the component tree");
  }

  return form;
};

export const BillProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [isOpenRecipientSidePanel, setIsOpenRecipientSidePanel] = useState(false);
  const [dynamicFieldsIdentifier, setDynamicFieldsIdentifier] = useState<Record<string, DynamicFormIdentifier>>({});
  const [recipientSelectedID, setRecipientSelectedID] = useState<undefined | number>(undefined);
  const [ocrData, setOCRData] = useState<OCRData | undefined>();
  const [withOCRData, setWithOCRData] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<ReactNode>();

  const handleClickReviewBtn = () => {
    history.push(PAYMENT_RUN_URL);
    setShowModal(false);
  };

  const handleBudgetOverlimitModal = (overlimitBillsCount: number, overlimitBudgetNames: string[]) => {
    setShowModal(true);

    if (overlimitBillsCount) {
      setPopupMessage(
        <>
          Payment run cannot be processed since {overlimitBillsCount} bill's status has changed to{" "}
          <Typography variant="body-content" size="s" weight={600}>
            Budget overlimit
          </Typography>
          . Kindly review the bills and try again.
        </>
      );
      return;
    }

    setPopupMessage(
      <>
        Payment run cannot be processed as you don't have sufficient budget limit in {overlimitBudgetNames.join(", ")}.
        Kindly review and adjust the budgets before proceeding with the payment run.
      </>
    );
  };

  return (
    <ErrorHandlerProvider>
      <BillContext.Provider
        value={{
          isOpenRecipientSidePanel,
          setIsOpenRecipientSidePanel,
          dynamicFieldsIdentifier,
          setDynamicFieldsIdentifier,
          recipientSelectedID,
          setRecipientSelectedID,
          ocrData,
          setOCRData,
          withOCRData,
          setWithOCRData,
          openBudgetOverlimitModal: handleBudgetOverlimitModal,
        }}
      >
        {children}
        <Modal
          showModal={showModal}
          size="m"
          title="Payment run cannot be processed"
          primaryActionButton={{
            title: "Review",
            action: handleClickReviewBtn,
          }}
        >
          <Typography
            tag="p"
            variant="body-content"
            size="s"
            style={{
              color: "var(--text-title-default)",
            }}
          >
            {popupMessage}
          </Typography>
        </Modal>
      </BillContext.Provider>
    </ErrorHandlerProvider>
  );
};

const initialPermissionAndSettings = {
  settingLoading: null,
  settings: null,
  viewAllowed: null,
  setupAllowed: null,
  permissionLoading: null,
};

export const PermissionAndSettingsContext = createContext<PermissionAndSettings>(initialPermissionAndSettings);

export const PermissionAndSettingsProvider = ({ children }) => {
  const [permissionAndSettings, setPermissionAndSettings] =
    useState<PermissionAndSettings>(initialPermissionAndSettings);
  const { data: getSetting, isLoading: isPaymentRunSettingLoading } = useMutableData(API_URL.getPaymentRunSetting);
  const getSettingData = useMemo(() => getSetting?.data?.payload?.setting, [getSetting?.data?.payload?.setting]);
  const paymentRunSettingEnabled = Boolean(getSettingData);

  const paymentRunViewPermission = {
    object: CASBIN_PAGES.PAYMENT_RUN_BILL_PAY_PAGE,
    action: PAYMENT_RUN_BILL_PAY_PAGE.READ_CYCLE,
  };
  const paymentRunSetUpPermission = {
    object: CASBIN_PAGES.PAYMENT_RUN_BILL_PAY_PAGE,
    action: PAYMENT_RUN_BILL_PAY_PAGE.READ_SETUP,
  };
  const [permission, permissionLoading] = usePermissionCheck(
    [paymentRunViewPermission, paymentRunSetUpPermission],
    true
  ) as PermissionCheckResultWithLoading;
  const viewAllowed = permission[0];
  const setUpAllowed = permission[1];

  useEffect(() => {
    const anyNullOrUndefined =
      [isPaymentRunSettingLoading, permissionLoading, paymentRunSettingEnabled, viewAllowed, setUpAllowed].filter(
        (val) => val === null || val === undefined
      ).length > 0;
    if (!anyNullOrUndefined) {
      setPermissionAndSettings({
        settingLoading: isPaymentRunSettingLoading,
        settings: paymentRunSettingEnabled,
        viewAllowed: viewAllowed,
        setupAllowed: setUpAllowed,
        permissionLoading: permissionLoading,
      });
    }
  }, [permissionLoading, isPaymentRunSettingLoading, viewAllowed, setUpAllowed, paymentRunSettingEnabled]);

  return (
    <PermissionAndSettingsContext.Provider value={permissionAndSettings}>
      {children}
    </PermissionAndSettingsContext.Provider>
  );
};
