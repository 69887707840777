import { PRODUCT_NAME } from "Redux/ModularProduct";
import { CARD_VENDOR_TYPE } from "constants/QuickAccess.constant";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

import { USER_ROLE } from "constants/Team.constant";

/**
 * Custom hook for retrieving navigation configurations based on organization settings,
 * user roles, and cookies.
 *
 * @returns {{
 *   isCardsPageAvailable: boolean,
 *   isReimbursementPageAvailable: boolean,
 *   isBillPayPageAvailable: boolean,
 *   isApprovalPoliciesPageAvailable: boolean,
 * }} - An object containing flags for page availability.
 */

export const useNavigationConfigs = () => {
  const IS_BILLPAY_PAGE_AVAILABLE = useCheckOrgConfigs(PRODUCT_NAME.BILLPAY);
  const IS_REIMBURSEMENT_PAGE_AVAILABLE = useCheckOrgConfigs(PRODUCT_NAME.CASH_REIMBURSEMENT);

  const isAdmin = getCookieValue(cookieNames.USER_COMPANY_ROLE) === USER_ROLE.ADMIN;
  const isAccountant = getCookieValue(cookieNames.USER_COMPANY_ROLE) === USER_ROLE.ACCOUNTANT;

  return {
    isCardsPageAvailable: useCheckOrgConfigs(PRODUCT_NAME.CARD_VENDOR) !== CARD_VENDOR_TYPE.NONE,
    isReimbursementPageAvailable: useCheckOrgConfigs(PRODUCT_NAME.CASH_REIMBURSEMENT),
    isBillPayPageAvailable: useCheckOrgConfigs(PRODUCT_NAME.BILLPAY),
    isApprovalPolicesPageAvailable:
      (isAdmin || isAccountant) && (IS_BILLPAY_PAGE_AVAILABLE || IS_REIMBURSEMENT_PAGE_AVAILABLE),
  };
};
