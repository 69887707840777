import React, { useContext, useEffect } from "react";

import "./index.scss";
import { FilterItem } from "Modules/DS/Filter";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { initalSelectState, ISelectContext, SelectContext, SelectProvider } from "Modules/DS/Select";
import AmountFilter from "./amount";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/ConfigureStore";
import {
  applyFilter,
  clearFilter,
  selectApprovalFilters,
  setCurrentPage,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import { getCurrencyFunc } from "Redux/Actions";
import { useAppSelector } from "../../../../hook";
import { BillFilter, FilterKey } from "../types";
import RequestDate from "./RequestDate";
import { ApprovalFilterContext } from "../context";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import dayjs from "dayjs";

const Filter = () => {
  return (
    <SelectProvider>
      <ApprovalFilters />
    </SelectProvider>
  );
};

const ApprovalFilters = () => {
  const { setSelect } = useContext<ISelectContext>(SelectContext);

  const dispatch = useDispatch();
  const approvalRequestFilter: BillFilter = useAppSelector(selectApprovalFilters);

  const { filters, setFilters } = useContext(ApprovalFilterContext);
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  useEffect(() => {
    dispatch(getCurrencyFunc());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: currencyData } = useSelector((state: RootState) => state.currencyReducer);
  const currencies = (currencyData.payload && currencyData.payload.currencies) || [];

  const handleSubmit = (filterKey: FilterKey, values) => {
    dispatch(setCurrentPage(1));
    switch (filterKey) {
      case FilterKey.Amount_Send:
        dispatch(applyFilter({ minSendAmount: values.min_amount, maxSendAmount: values.max_amount }));
        break;
      case FilterKey.Amount_Receive:
        dispatch(applyFilter({ minReceiveAmount: values.min_amount, maxReceiveAmount: values.max_amount }));
        break;
      case FilterKey.Invoice_Amount:
        dispatch(applyFilter({ minInvoiceAmount: values.min_amount, maxInvoiceAmount: values.max_amount }));
        break;
      case FilterKey.Request_Date:
        dispatch(
          applyFilter({
            startDate: dayjs(values.start_date).format("YYYY-MM-DD"),
            endDate: dayjs(values.end_date).format("YYYY-MM-DD"),
          })
        );
        break;
    }
    setSelect({
      ...initalSelectState,
    });
    setFilters((filters) => {
      return {
        ...filters,
        [filterKey]: true,
      };
    });
  };

  const handleClear = (filterKey: FilterKey) => {
    dispatch(clearFilter(filterKey));
    setSelect({ ...initalSelectState });
    setFilters((filters) => {
      return {
        ...filters,
        [filterKey]: false,
      };
    });
  };

  return (
    <div className="approval-filter">
      <RequestDate
        id={FilterKey.Request_Date}
        defaultValue="Request Date"
        filter={{ start_date: approvalRequestFilter.startDate, end_date: approvalRequestFilter.endDate }}
        onApply={(value) => {
          handleSubmit(FilterKey.Request_Date, value);
        }}
        onClear={() => {
          handleClear(FilterKey.Request_Date);
        }}
      />

      {isSaasWithPaymentsOrg && (
        <FilterItem
          id={FilterKey.Amount_Send}
          title="Amount You Send"
          selected={filters[FilterKey.Amount_Send]}
          popoverWidth={POPOVER_WIDTH.L}
          clearFilter={() => handleClear(FilterKey.Amount_Send)}
        >
          <AmountFilter
            currencyData={currencies}
            initialMinAmount={approvalRequestFilter.minSendAmount}
            initialMaxAmount={approvalRequestFilter.maxSendAmount}
            handleSubmit={handleSubmit}
            filterKey={FilterKey.Amount_Send}
          />
        </FilterItem>
      )}

      <FilterItem
        id={isSaasWithPaymentsOrg ? FilterKey.Amount_Receive : FilterKey.Invoice_Amount}
        title={isSaasWithPaymentsOrg ? "Amount Recipient Gets" : "Invoice Amount"}
        selected={filters[isSaasWithPaymentsOrg ? FilterKey.Amount_Receive : FilterKey.Invoice_Amount]}
        popoverWidth={POPOVER_WIDTH.L}
        clearFilter={() => handleClear(isSaasWithPaymentsOrg ? FilterKey.Amount_Receive : FilterKey.Invoice_Amount)}
      >
        <AmountFilter
          currencyData={currencies}
          initialMinAmount={
            isSaasWithPaymentsOrg ? approvalRequestFilter.minReceiveAmount : approvalRequestFilter.minInvoiceAmount
          }
          initialMaxAmount={
            isSaasWithPaymentsOrg ? approvalRequestFilter.maxReceiveAmount : approvalRequestFilter.maxInvoiceAmount
          }
          handleSubmit={handleSubmit}
          filterKey={isSaasWithPaymentsOrg ? FilterKey.Amount_Receive : FilterKey.Invoice_Amount}
        />
      </FilterItem>
    </div>
  );
};

export default Filter;
