import React, { useMemo, useState } from "react";
import cn from "classnames";
import { ActionChip } from "@spenmo/splice";

import { SUMMARY_TAB_VALUES } from "Views/SubscriptionManagement/Constants";

import { Details } from "./Details";
import { Payment } from "./Payment";
import { ActivityLog } from "./ActivityLog";

import styles from "./index.module.scss";

const summaryTabs = [
  {
    label: "Details",
    value: SUMMARY_TAB_VALUES.DETAILS,
    content: Details,
  },
  {
    label: "Payment",
    value: SUMMARY_TAB_VALUES.PAYMENT,
    content: Payment,
  },
  {
    label: "Activity Log",
    value: SUMMARY_TAB_VALUES.ACTIVITY_LOG,
    content: ActivityLog,
  },
];

const findDefaultTab = (tab) => {
  if ([SUMMARY_TAB_VALUES.ACTIVITY_LOG, SUMMARY_TAB_VALUES.PAYMENT, SUMMARY_TAB_VALUES.DETAILS].includes(tab)) {
    return tab;
  }

  return SUMMARY_TAB_VALUES.DETAILS;
};

export const Tabs = ({ defaultActiveTab }: { defaultActiveTab: SUMMARY_TAB_VALUES }) => {
  const [activeTab, setActiveTab] = useState<SUMMARY_TAB_VALUES>(findDefaultTab(defaultActiveTab));

  const onTabChange = (value: SUMMARY_TAB_VALUES) => {
    setActiveTab(value);
  };

  const TabContent = useMemo(
    () => summaryTabs.find((tabDetails) => tabDetails.value === activeTab).content,
    [activeTab]
  );

  // Action chip group doesn't provide onClick and width adjustment,
  // so made a custom ActionChipGroup to have both functionalities.
  // Will ask ritik to add these in splice.
  return (
    <>
      <div role="tablist" className={cn("sp-actionchip-group", styles.actionChipGroup)}>
        {summaryTabs.map(({ label, value }) => {
          return (
            <ActionChip
              active={activeTab === value}
              onClick={() => onTabChange(value)}
              role="tab"
              label={label}
              className={styles.actionChip}
              key={value}
            />
          );
        })}
      </div>
      <TabContent />
    </>
  );
};
