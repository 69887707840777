import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";

import { tinyEmptyComingSoonWithoutBackground } from "assets/img";
import { Button, Typography } from "@spenmo/splice";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import GoogleAuthenticator from "Views/SecurityHub/GoogleAuthenticator";
import { resetGAData } from "Redux/Actions/SecurityHub/GoogleAuthenticator/ActivationActions";

import {
  useToasterContext,
  TOASTER_STATUS_TYPE,
} from "Views/Settings/context/ToasterContext";
import useLoading from "utility/useLoading";
import { useAnalytic } from "Views/Settings/hooks";
import { resetPassword } from "./datacall";

import styles from "./index.module.scss";

const VALID_PASSWORD_REGEX =
  /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])((?=\S*[\W]))\S*^/;

const SecurityHub = ({
  newSettingPage = false,
}: {
  newSettingPage?: boolean;
}) => {
  const [form] = useForm();
  const location = useLocation<{ message?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setToaster } = useToasterContext();
  const [updatePassword, loading] = useLoading(resetPassword);
  const [hasChanged, setHasChanged] = useState(false);
  useAnalytic({ eventSource: "Personal", eventSubSource: "Security" });

  const onFinish = async (value) => {
    const { data } = await updatePassword(value);
    if (data.status === HTTP_STATUS_CODE.OK) {
      form.resetFields(["old_password", "new_password", "confirm_password"]);
      setToaster({
        message: "Your password has been updated",
        show: true,
        type: TOASTER_STATUS_TYPE.SUCCESS,
      });
    }
  };

  const detectChange = () => {
    const hasChanged = Object.values(
      form.getFieldsValue(["old_password", "new_password", "confirm_password"]),
    ).some((value) => Boolean(value));
    setHasChanged(hasChanged);
  };

  useEffect(() => {
    const message = location.state?.message;

    if (message) {
      setToaster({ show: true, message, type: TOASTER_STATUS_TYPE.SUCCESS });
      history.replace({ state: {} });
      dispatch(resetGAData);
    }
  }, [location.state]);

  return (
    <>
      <div
        className={cn("section section--white section--full-height", {
          [styles.paddingLess]: newSettingPage,
          [styles.container]: !newSettingPage,
        })}
      >
        <Typography
          size="m"
          variant="headline-content"
          tag="h3"
          className={styles.title}
        >
          Security
        </Typography>
        <Typography
          tag="p"
          size="m"
          variant="body-content"
          className={styles.caption}
        >
          Protect your account by setting up 2FA and reviewing your password
          regularly{" "}
        </Typography>
        <Row className={cn(styles.section, styles.googleAuthenticator)}>
          <GoogleAuthenticator />
        </Row>

        <div className={cn(styles.section, styles.flex)}>
          <img
            width={120}
            height={120}
            className={styles.illustration}
            src={tinyEmptyComingSoonWithoutBackground}
            alt="coming soon illustration"
          />
          <div className={styles.body}>
            <Typography
              tag="h4"
              size="m"
              variant="body-content"
              className={styles.header}
            >
              Password Change
            </Typography>
            <Typography
              tag="p"
              size="m"
              variant="body-content"
              className={styles.passwordCaption}
            >
              Make sure to set a secure password and change it regularly to keep
              your account safe
            </Typography>
            <Form
              form={form}
              onFinish={onFinish}
              onChange={detectChange}
              className={styles.form}
            >
              <Form.Item
                name="old_password"
                label={<span>Current Password</span>}
                className={styles.input}
                rules={[
                  {
                    required: true,
                    message: "Please enter your current password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Input current password"
                  className={styles.password}
                  autoComplete="off"
                />
              </Form.Item>

              <Form.Item
                name="new_password"
                label={<span>New Password</span>}
                className={styles.input}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    pattern: VALID_PASSWORD_REGEX,
                    message:
                      "Password must be at least 8 letters long including at least one lowercase letter, one uppercase letter, one number and one special character",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter new password"
                  className={styles.password}
                  autoComplete="off"
                />
              </Form.Item>

              <Form.Item
                name="confirm_password"
                label={<span>Confirm New Password</span>}
                className={styles.input}
                rules={[
                  { required: true, message: "Please confirm your password!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The passwords you entered do not match"),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Re-enter new password"
                  className={styles.password}
                  autoComplete="off"
                />
              </Form.Item>
              <Button
                variant="primary"
                loading={loading}
                type="submit"
                className={styles.submitButton}
                disabled={!hasChanged}
              >
                Save Changes
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityHub;
