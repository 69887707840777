import { useMemo } from "react";
import useSWR, { SWRConfiguration } from "swr";
import qs from "query-string";
import dayjs from "dayjs";

import { useMutableData } from "API/useData";

import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { CustomFieldType, FieldType, VendorDynamicFieldNames } from "../components/FormGenerator/type";
import { FieldGenerator, SaaSConfig } from "../BillForm/type";
import { API_URL, EMAIL_REGEX } from "../constants";
import { GetBaseAuthObject, removeEmptyProperty } from "utility";
import { getData } from "API/Client";

/**
 * This hooks is a dynamic fields generator that created
 * to prepare the possibilities of moving this to the
 * BE when it becoming too complex.
 * PRETEND THAT THIS IS AN API RESPONSE
 * @param body an object filled with conditions for adding/removing fields
 * @returns formFields used for BillDetail form
 */
const getBillDetailFields = async ([
  url,
  step,
  vendorDetailPayload,
  billDetailPayload,
  token,
  vendorIDFromUrl,
  dashboardCurrency,
]: any[]) => {
  const parsedUrl = qs.parseUrl(url, { parseBooleans: true });
  const { isAddNotes: queryIsAddNotes, isConnectedWithXero, countryCode } = parsedUrl.query || {};

  const {
    invoiceNumber,
    issuedDate,
    dueDate,
    expenseCategoryID,
    taxID,
    tags,
    billTax,
    clientNotes,
    beneficiary,
    isAddNotes: defaultIsAddNotes,
    swiftPaymentChargeType,
  } = removeEmptyProperty(billDetailPayload || {});

  const { vendorID } = removeEmptyProperty(beneficiary || {});
  const { invoiceAmount, invoiceCurrency } = removeEmptyProperty(billTax || {});
  const isAddNotes = Boolean(queryIsAddNotes) || defaultIsAddNotes;

  let vendorCurrencyCode;

  if (vendorDetailPayload?.dynamicFields) {
    vendorCurrencyCode = vendorDetailPayload?.dynamicFields.find((item) => {
      return item.alias === VendorDynamicFieldNames.currencyCode;
    })?.value;
  }

  const isPHCompany = countryCode === "PH";

  const fields: FieldGenerator[] = [
    {
      label: "Recipient",
      name: "vendorID",
      rules: {
        required: "Select a recipient",
      },
      defaultValue: vendorID || +vendorIDFromUrl || undefined,
      fieldProps: {
        fieldType: CustomFieldType.RECIPIENT_SELECT,
        isOnChangeRefetch: true,
        resetFields: ["isAddNotes", "billTax.invoiceCurrency"],
      },
    },
    {
      label: "Invoice Number",
      name: "billNumber",
      defaultValue: invoiceNumber,
      fieldProps: {
        fieldType: CustomFieldType.INVOICE_NUMBER,
        placeholder: "Enter invoice number",
      },
    },
    {
      name: "issuanceDueDate",
      fieldGroup: [
        {
          label: "Issuance Date",
          name: "issuedDate",
          defaultValue: issuedDate,
          fieldProps: {
            fieldType: FieldType.DATE,
            placeholder: "Select a date",
            disabledFutureDates: true,
            hasClear: true,
          },
        },
        {
          label: "Due Date",
          name: "dueDate",
          defaultValue: dueDate,
          fieldProps: {
            fieldType: CustomFieldType.DATE_LIST,
            placeholder: "Select a date",
            refField: "issuedDate",
          },
        },
      ],
    },
    {
      label: "Category",
      name: "expenseCategoryID",
      defaultValue: expenseCategoryID ? Number(expenseCategoryID) : undefined,
      rules: {
        required: false,
      },
      fieldProps: {
        fieldType: CustomFieldType.CATEGORY,
        placeholder: "Select category",
      },
    },
    {
      label: "Tax",
      name: "taxID",
      defaultValue: taxID,
      rules: {
        required: isPHCompany,
      },
      fieldProps: {
        fieldType: CustomFieldType.TAX,
        placeholder: "Select tax",
      },
    },
    {
      label: "Invoice Amount",
      name: "currencyInvoiceAmount",
      fieldGroup: [
        {
          name: "billTax.invoiceCurrency",
          defaultValue: invoiceCurrency || vendorCurrencyCode || dashboardCurrency,
          rules: {
            required: "Select a currency",
          },
          fieldProps: {
            fieldType: FieldType.SELECT,
            /* get from fieldProps because
            the CurrencyInput can be broken down
            into 2 fieldGroup */
            // countryCode: vendorCountryCode, only for withholding tax
          },
        },
        {
          name: "billTax.invoiceAmount",
          defaultValue: invoiceAmount,
          rules: {
            required: "Enter an amount",
            positive: {
              value: 0,
              message: "Entered amount must be greater than 0",
            },
          },
          fieldProps: {
            fieldType: FieldType.INPUT,
            placeholder: "Enter amount",
            isOnChangeRefetch: true,
            youPayName: "totalPaidAmount",
            recipientName: "amount",
            swiftPaymentChargeType,
          },
        },
      ],
    },
    {
      name: "isAddNotes",
      defaultValue: isAddNotes,
      fieldProps: {
        fieldType: FieldType.CHECKBOX,
        textLabel: isConnectedWithXero ? "Add tags and notes" : "Add notes",
        isOnChangeRefetch: true,
        resetFields: [...(isAddNotes ? ["notes"] : []), ...(isAddNotes && isConnectedWithXero ? ["tags"] : [])],
      },
    },
  ];

  if (isAddNotes) {
    if (isConnectedWithXero) {
      const tagsField = {
        label: "Tags",
        name: "tags",
        defaultValue: tags,
        fieldProps: {
          fieldType: CustomFieldType.TAGS,
          placeholder: "Select tags",
        },
      };

      fields.push(tagsField);
    }

    const notesField = {
      label: "Notes",
      name: "notes",
      defaultValue: clientNotes,
      fieldProps: {
        fieldType: FieldType.INPUT,
        placeholder: "Write a note for your team",
      },
    };

    fields.push(notesField);
  }

  return {
    data: {
      payload: {
        step: 1,
        next: 2,
        title: undefined,
        fields,
      },
    },
  };
};

/**
 * This hooks is a dynamic fields generator that created
 * to prepare the possibilities of moving this to the
 * BE when it becoming too complex.
 * PRETEND THAT THIS IS AN API RESPONSE
 * @param body an object filled with conditions for adding/removing fields
 * @returns formFields used for PaymentDetail form
 */
const getPaymentDetailFields = ([
  url,
  step,
  vendorDetailPayload,
  billDetailPayload,
  token,
  vendorIDFromUrl,
  dashboardCurrency,
  isPaymentRunOn,
  saasConfig,
]: any[]) => {
  const parsedUrl = qs.parseUrl(url, { parseBooleans: true });
  const { countryCode, totalPaidAmount } = parsedUrl.query || {};

  const {
    swiftPaymentChargeType,
    paymentScheduleTime,
    isPayImmediately,
    teamID,
    additionalAttachments,
    isSuggestPaymentDate,
    receiptEmails,
    beneficiary,
  } = removeEmptyProperty(billDetailPayload || {});
  const { remarks } = removeEmptyProperty(beneficiary || {});

  const isMoreThan500 = countryCode === "PH" && Number(totalPaidAmount) >= 500000;

  const fieldAllowed = (fieldname) => saasConfig.fields.find((field) => field.field === fieldname);

  const fields: FieldGenerator[] = [];
  if (fieldAllowed("twoWayInput")) {
    fields.push(
      ...[
        {
          name: "twoWayPayment",
          fieldGroup: [
            {
              label: "Recipient gets",
              name: "amount",
              tooltip:
                "Recipient currency is determined by the saved recipient details. Edit the recipient details to change the currency.",
              rules: {
                required: "Enter an amount",
                positive: {
                  value: 0,
                  message: "Entered amount must be greater than 0",
                },
              },
              fieldProps: {
                fieldType: CustomFieldType.TWO_WAY_PAYMENT,
                placeholder: "Enter amount",
              },
            },
            {
              label: "You pay",
              name: "totalPaidAmount",
              rules: {
                required: "Enter an amount",
                positive: {
                  value: 0,
                  message: "Entered amount must be greater than 0",
                },
              },
              fieldProps: {
                fieldType: CustomFieldType.TWO_WAY_PAYMENT,
                placeholder: "Enter amount",
                isOnChangeRefetch: countryCode === "PH",
              },
            },
            {
              name: "swiftPaymentChargeType",
              defaultValue: swiftPaymentChargeType || "OUR",
              fieldProps: {
                fieldType: CustomFieldType.TWO_WAY_PAYMENT,
              },
            },
          ],
        },
      ]
    );
  }

  const paymentScheduledOn = {
    name: "paymentScheduleDate",
    fieldGroup: [
      {
        name: "paymentScheduleDate",
        label: "Payment Scheduled On",
        rules: {
          required: fieldAllowed("paymentScheduleTimeRequired") ? "This field cannot be empty" : false,
        },
        defaultValue:
          paymentScheduleTime !== "-"
            ? paymentScheduleTime
            : isPayImmediately
            ? dayjs().format("YYYY-MM-DD")
            : undefined,
        fieldProps: {
          fieldType: CustomFieldType.PAYMENT_SCHEDULE,
          placeholder: "Select a date",
          hasClear: false,
          help: "ETA within",
        },
      },
      ...(fieldAllowed("paymentScheduleTimeRequired")
        ? [
            {
              name: "isSuggestPaymentDate",
              defaultValue: isSuggestPaymentDate,
              fieldProps: {
                textLabel: "Suggest me a payment date",
                fieldType: CustomFieldType.PAYMENT_SCHEDULE,
              },
            },
          ]
        : []),
    ],
  };

  const budgetOrPayFromField = {
    label: fieldAllowed("team").label,
    name: "teamID",
    defaultValue: teamID,
    rules: {
      required: "Select a wallet",
    },
    fieldProps: {
      fieldType: CustomFieldType.PAY_FROM,
      placeholder: "Enter Pay from",
    },
  };

  if (!saasConfig.isPaymentEnabled) {
    if (fieldAllowed("team")) {
      fields.push(budgetOrPayFromField);
    }
  }

  if (!isPaymentRunOn && (fieldAllowed("paymentScheduleTimeOptional") || fieldAllowed("paymentScheduleTimeRequired"))) {
    fields.push(paymentScheduledOn);
  }

  if (saasConfig.isPaymentEnabled) {
    if (fieldAllowed("team")) {
      fields.push(budgetOrPayFromField);
    }
  }

  if (fieldAllowed("beneficiaryRemarks")) {
    fields.push({
      label: "Remark for Recipient",
      name: "beneficiaryRemarks",
      defaultValue: remarks,
      rules: {
        required: false,
      },
      fieldProps: {
        fieldType: FieldType.INPUT,
        placeholder: "Enter remarks",
        tooltip: "Remarks will be shown on receipt and recipient's bank statement",
      },
    });
  }

  if (fieldAllowed("sendPaymentUpdatesTo")) {
    fields.push({
      label: "Send Payment Updates to",
      name: "sendPaymentUpdatesTo",
      defaultValue: receiptEmails?.split(","),
      rules: {
        pattern: EMAIL_REGEX,
      },
      fieldProps: {
        fieldType: FieldType.INPUTTAG, // TO DO: should change to CustomType for email validation
        placeholder: "Enter emails",
        tooltip:
          "We will automatically send receipts to the Recipient email. If you want to send receipts to additional people, add these email(s) here",
      },
    });
  }

  fields.push({
    label: "Attachment",
    name: "additionalAttachments",
    rules: {
      required: false,
    },
    fieldProps: {
      fieldType: CustomFieldType.ATTACHMENT,
      attachments: additionalAttachments,
    },
  });

  return {
    data: {
      payload: {
        step: 2,
        next: isMoreThan500 ? 3 : undefined, // 3 (if additional is exists) or undefined
        title: undefined,
        fields,
      },
    },
  };
};

const getAdditionalFields = () => {
  // currently only for PH
  const fields: FieldGenerator[] = [
    {
      label: "Recipient Address",
      name: "benificiaryAddress",
      rules: {
        required: "Input address up to 255 characters",
        maxLength: 255,
      },
      fieldProps: {
        fieldType: FieldType.TEXT_AREA,
        placeholder: "Enter Recipient Address",
        maxLength: 255,
        showCount: true,
        autoSize: { minRows: 4, maxRows: 4 },
      },
    },
  ];

  return {
    data: {
      payload: {
        step: 3,
        next: undefined,
        title: "Additional transfer details",
        fields: fields,
      },
    },
  };
};

/**
 * SWR-like hooks special for BillDetail
 * @returns swr object
 */
export const useBillDetailFields = (url: string | null, options?: SWRConfiguration) => {
  const orgId = GetBaseAuthObject().orgId;
  const parsedURL = { ...qs.parseUrl(url) };
  const { vendorID, billID, source, dashboardCurrency } = parsedURL.query || {};

  // fetch xeroAuth here since the BE have this data
  const xeroAuthURL = qs.stringifyUrl(
    {
      url: API_URL.xeroAuth,
      query: {
        organisation_id: orgId,
      },
    },
    {
      skipEmptyString: true,
    }
  );
  const { data: xeroAuth } = useMutableData(xeroAuthURL);

  const { data: tag } = useSWR(
    Boolean(xeroAuth?.data?.payload?.has_valid_token)
      ? [API_URL.xeroTag, getCookieValue(cookieNames.AUTH_TOKEN)]
      : null,
    ([url]: string[]) => {
      return getData(url, "", false, {
        headers: { "X-Organisation-Id": orgId },
      });
    },
    {
      revalidateOnFocus: false,
      ...options,
    }
  );

  const { data: paymentRunData } = useMutableData(API_URL.getPaymentRunSetting);

  const isPaymentRunOn = useMemo(() => Boolean(paymentRunData?.data?.payload?.setting), [paymentRunData]);

  const { data: saasConfigResponse } = useMutableData(API_URL.saasConfig);
  const saasConfig: SaaSConfig = useMemo(() => saasConfigResponse?.data?.payload, [saasConfigResponse?.data?.payload]);

  const isEditBill = billID && source;

  const billDetailURL = qs.stringifyUrl({
    url: `${API_URL.disbursementV1}/bill/${billID}`,
    query: {
      source,
    },
  });

  // should be put inside the bill detail
  const { data: billDetailData, isValidating: billDetailLoading } = useMutableData(isEditBill ? billDetailURL : null);

  const billDetailPayload = useMemo(() => billDetailData?.data?.payload, [billDetailData]);

  const { vendorID: billDetailVendorID } = billDetailPayload?.beneficiary || {};

  const { data: vendorDetailData, error: vendorDetailError } = useMutableData(
    vendorID || billDetailVendorID ? `${API_URL.recipientDetail}/${vendorID || billDetailVendorID}` : null
  );

  const vendorDetailPayload = useMemo(() => vendorDetailData?.data?.payload, [vendorDetailData]);

  const swr = useSWR(
    () => {
      parsedURL.query = {
        ...parsedURL.query,
        // BE should be able to get this from orgId
        isConnectedWithXero: String(xeroAuth.data.payload.has_valid_token && tag?.data?.payload?.data?.length > 0),
      };

      if (!parsedURL.query.step) {
        throw new Error("no step in the query");
      }

      if (vendorID && vendorDetailError) {
        throw new Error("failed to fetch vendor detail");
      }

      if (isEditBill && billDetailLoading) {
        return null;
      }

      const billDetailURL = qs.stringifyUrl(parsedURL);

      return [
        billDetailURL,
        String(parsedURL.query.step),
        vendorDetailPayload,
        billDetailPayload,
        getCookieValue(cookieNames.AUTH_TOKEN),
        vendorID,
        dashboardCurrency,
        isPaymentRunOn,
        saasConfig,
      ];
    },
    (params) => {
      const step = params[1];
      switch (step) {
        case "1": {
          return getBillDetailFields(params);
        }
        case "2": {
          return getPaymentDetailFields(params);
        }
        case "3": {
          return getAdditionalFields();
        }
      }
    },
    options
  );

  return swr;
};
