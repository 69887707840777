import { GetOrgId } from "utility";

export enum ALL_TRXNS_SERVICE {
  GET_TRXNS_LIST = `/ms/spm-reporting/v1/transactions`,
  GET_TRXNS_COUNT = `/ms/spm-reporting/v1/bankstatement/count`,
  GET_RECEIPTS_COUNT = `/ms/spm-reporting/v1/transactions/countreceipts`,
  DOWNLOAD_BANK_STATEMENT = `/ms/spm-reporting/v1/bankstatement`,
  DOWNLOAD_CSV = `/ms/spm-reporting/v1/transactions/downloadcsv`,
  DOWNLOAD_RECEIPT = `/ms/spm-reporting/v1/transactions/downloadreceipts`,
  SEARCH_MERCHANTS = `/api/v1/transactions/search_merchants`,
}

export const TRANSACTION_DETAILS = () =>
  ({
    GET_TRANSACTION_DETAILS: (id: string) => `/ms/spm-reporting/v1/transaction/${id}`,
    GET_RECEIPT_DETAILS: (trxnId: string, trxnNum: string) =>
      `/api/v1/receipt/?organisation_id=${GetOrgId()}&transaction_number=${trxnNum}&transaction_id=${trxnId}`,
    SAVE_TRANSACTION_DETAILS: "/api/v1/receipt/",
    LINK_TRANSACTION_TO_SUBSCRIPTION: "/ms/spm-organisation/v1/expenses/subscription/link",
    GET_CR_PAYOUT_DETAILS: (trxnNum: string) =>
      `/ms/spm-disbursement/v1/reimbursement/settlement-detail?transactionCode=${trxnNum}`,
  } as const);

export enum BILLS_TRXNS_SERVICE {
  SEARCH_MERCHANTS = `/ms/spm-disbursement/v1/bill/specific-info`,
}

export enum CARDS_TRXNS_SERVICE {
  GET_CARDS_TRXNS_LIST = `ms/b2b-card/v1/transaction/`,
}
