import React from "react";
import FormGenerator from "Views/Bills/V2/components/FormGenerator";

import { flattenObj } from "utility";
import { CustomFieldType } from "Views/Bills/V2/components/FormGenerator/type";
import {
  BasicFieldGenerator,
  BillFieldGenerator,
  BillFlowEnum,
  BillFormFields,
  FieldGenerator,
  OCRData,
} from "Views/Bills/V2//BillForm/type";

/**
 * Helper function is created to render multiple fields inside
 * 1 Component. The idea is to use your own FormGenerator
 * and separate the props for each field inside the Component.
 * @param fieldList Array of field list
 * @returns array of fields
 */
export const generateBillDetailFields = (
  fieldList: FieldGenerator[] = [],
  ocrData: OCRData,
  formValues: BillFormFields
) => {
  const flattenFormValues = flattenObj(formValues);

  // comparing value based on name
  const compareFieldWithOCR = (name: string, dataType?: "string") => {
    switch (dataType) {
      case "string": {
        return String(flattenFormValues?.[name]) === String(ocrData?.billData?.[name]);
      }
      default: {
        return flattenFormValues?.[name] === ocrData?.billData?.[name];
      }
    }
  };

  const fields = fieldList.map((field: FieldGenerator) => {
    const data: BillFieldGenerator = { ...field };
    const { name } = data;

    /**
     * Generated OCR Props is used for straightforward field
     * and for special fields (Ex: field with fieldsGroup)
     * @param name Bill details field key
     */
    const generateOCRProps = (name: string | string[], field?: BasicFieldGenerator, dataType?: "string") => {
      // reset ocr related data
      (field || data).withOCR = undefined;
      (field || data).isSameWithOCR = undefined;

      // IMPROVEMENT: OCR Data should not be mapped by FE
      // rather it should be called when calling the Dynamic API
      if (ocrData) {
        // need to check every name for 1 row with multiple fields
        if (Array.isArray(name)) {
          let isSameWithOCR = true;

          name.forEach((n) => {
            // check withOCR should be OR
            if (n in ocrData.billData) {
              (field || data).withOCR = true;
            }
            // check isSameWithOCR should be AND
            isSameWithOCR = isSameWithOCR && compareFieldWithOCR(n, dataType);
          });

          (field || data).isSameWithOCR = isSameWithOCR;
        } else if (name in ocrData.billData) {
          (field || data).withOCR = true;
          (field || data).isSameWithOCR = compareFieldWithOCR(name, dataType);
        }
      }

      return field;
    };

    // for general data
    generateOCRProps(name);

    if ("fieldGroup" in data) {
      switch (name) {
        case "issuanceDueDate": {
          data.fieldGroup.forEach((field: BasicFieldGenerator, index: number) => {
            const { name } = field;
            const modifiedFields = generateOCRProps(name, field);
            data.fieldGroup[index] = {
              ...field,
              ...modifiedFields,
            };
          });
          break;
        }
        case "currencyInvoiceAmount": {
          // IMPROVEMENT: don't hard code the fields

          // Hard coded for invoice amount by
          // transforming the field
          const [currency, amount] = data.fieldGroup;
          data.name = amount.name;
          data.rules = amount.rules;
          data.defaultValue = amount.defaultValue;

          if (ocrData) {
            generateOCRProps([amount.name, currency.name], undefined, "string");
          }

          // custom render
          data.render = ({ field }) => {
            const { name, onChange, value } = field;
            // fieldType will be excluded from the currencyProps
            const { fieldType, ...currencyProps } = currency.fieldProps;

            // IMPROVEMENT: doing this since the currency default value is static
            // and it tempering with the setValue ocrData from the Uploader component
            const currencyValue = ocrData?.billData?.[currency.name] || currency.defaultValue;

            return (
              <FormGenerator
                {...amount.fieldProps}
                fieldType={CustomFieldType.CURRENCY_INPUT}
                id={name}
                placeholder="Enter amount"
                selectProps={{
                  ...currencyProps,
                  currencyCode: currencyValue,
                  controllerProps: { name: currency.name, rules: currency.rules, defaultValue: currencyValue },
                }}
                name={name}
                amount={value}
                onChangeAmount={onChange}
              />
            );
          };
        }
      }
    }

    return data;
  });
  return fields;
};

export const billSource = {
  [BillFlowEnum.EDIT_BILL]: "edit_bill",
  [BillFlowEnum.EDIT_DRAFT]: "edit_draft",
};
