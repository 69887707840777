export const TRXNS_PAGE_TITLE = "Transactions";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong, Please try again.";

export const DOWNLOAD_SUCCESS_MSG = "Data exported and downloaded succesfully.";

export enum TRXN_ELEMENT_IDS {
  ICON_CELL = "transaction--element__icon--cell",
  ADD_ATTACHMENT = "transaction--element__add--attachment",
}

export enum TRXN_ELEMENT_CLASS {
  TABS = "transaction--tabs",
  ACTIONS = "trxn_action",
  TABLE = "trxn_page",
  PAGINATION = "trxn_pagination",
  PAGINATION_CONTENT = "trxn_pagination-title",
  DATE_FILTER = "_transactions_date",
}

export enum TRANSACTION_TYPES {
  TRANSFER = "Transfer",
  TOPUP = "Topup",
  INCENTIVE = "Incentive",
  REIMBURSEMENT = "Cash Reimbursement",
  CARD_EXPENSE = "Card (Expense)",
  CARD_SETTLEMENT = "Card (Settlement)",
  CARD_REVERSAL = "Card (Reversal)",
  CARD_REFUND = "Card (Refund)",
  BILL_PAY_EXPENSE = "Bill Payment",
  BILL_PAY_EXPENSE_REVERSAL = "Bill Payment (Reversal)",
  OTHERS = "Others",
  OTHERS_PAYOUT = "Others (Payout)",
  OTHERS_REVERSAL = "Others (Payout Reversal)",
  FEES = "Fees",
  FEES_REFUND = "Fees (Refund)",
}

export enum TRANSACTION_SUB_TYPES {
  REVERSAL = "Reversal",
  EXPENSE = "Expense",
  REFUND = "Refund",
  PAYOUT = "Payout",
  Settlement = "Settlement",
}

export const EXPENSE_DETAILS_TYPES: Set<TRANSACTION_TYPES> = new Set([
  TRANSACTION_TYPES.BILL_PAY_EXPENSE,
  TRANSACTION_TYPES.BILL_PAY_EXPENSE_REVERSAL,
  TRANSACTION_TYPES.REIMBURSEMENT,
  TRANSACTION_TYPES.CARD_EXPENSE,
  TRANSACTION_TYPES.CARD_REFUND,
  TRANSACTION_TYPES.CARD_SETTLEMENT,
  TRANSACTION_TYPES.CARD_REVERSAL,
  TRANSACTION_TYPES.OTHERS,
  TRANSACTION_TYPES.OTHERS_PAYOUT,
  TRANSACTION_TYPES.OTHERS_REVERSAL,
  TRANSACTION_TYPES.FEES,
  TRANSACTION_TYPES.INCENTIVE,
]);

export * from "./SmartCSV";
export * from "./Details";
export * from "./Modal";
export * from "./List";
export * from "./Tabs";
export * from "./All";
export * from "./Bills";
