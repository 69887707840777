import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState } from "react";
import axios, { Canceler } from "axios";
import { mapByKey } from "utility/dataTypes/array";
import useLoading from "utility/useLoading";
import styles from "./DynamicForm.module.scss";
import { fetchDynamicFields } from "./api";
import { generateField } from "./fields/utils/fieldGenerator";
import { setAncestorIDs } from "./fields/utils/parser";
import { DynamicFormContext } from "./store";
import { DynamicFormField, DynamicFormFieldWithAncestor, DynamicFormProps } from "./types";
import { sortByOrderNumber } from "Views/Bills/helper";
import LoaderIcon from "Views/State/Loading/LoaderIcon";

const DynamicForm: React.FC<DynamicFormProps> = ({
  billID,
  vendorId,
  combinationIDs = [],
  currency,
  destinationCountry,
  form,
  ocrData,
  trackView,
  trackEdit,
  source,
}) => {
  const [fields, setFields] = useState<Record<string, DynamicFormFieldWithAncestor>>({});
  const [isNewDataFields, setIsNewDataFields] = useState<Record<string, boolean>>({});

  // request key that generated the first time we request dynamic form fields
  const [key, setKey] = useState("");

  // list of fields that are able to trigger additional fields when the value changed
  const [chainFields, setChainFields] = useState<Record<string, unknown>>({});

  const [_fetchDynamicFields, isLoading] = useLoading(fetchDynamicFields, undefined, {}, false);

  const fieldList = useMemo(() => Object.values(fields).sort((a, b) => a.orderNumber - b.orderNumber), [fields]);

  useEffect(() => {
    const options = Object.values(fields).reduce((prev, curr) => {
      if (curr.options?.length) {
        return [...prev, { alias: curr.alias, options: curr.options, fieldID: curr.fieldID, label: curr.label }];
      }
      return prev;
    }, []);
    form.setFieldsValue({
      dynamicFieldsOptions: options,
    });
  }, [fields, form]);

  const onChangeValue = (fieldID: number, value: unknown) => {
    const dynamicFieldsByFieldID = form.getFieldValue("dynamicFieldsByFieldID");
    const dynamicFieldsManuallyChanged = form.getFieldValue("dynamicFieldsManuallyChanged");
    const newDynamicFieldsByFieldID = {
      ...dynamicFieldsByFieldID,
      [fieldID]: value,
    };
    const newDynamicFieldsManuallyChanged = {
      ...dynamicFieldsManuallyChanged,
      [fieldID]: true,
    };
    form.setFieldsValue({
      dynamicFieldsByFieldID: newDynamicFieldsByFieldID,
      dynamicFieldsManuallyChanged: newDynamicFieldsManuallyChanged,
    });
  };

  const onFetchValue = (fieldID: number, value: unknown, autoChain?: boolean) => {
    const toBeDeleted = Object.values(fields).filter((item) => {
      return item.ancestorIDs.includes(fieldID);
    });

    const tmpFields = { ...fields };
    const tmpChainFields = { ...chainFields };

    // remove sub fields
    toBeDeleted.forEach((item) => {
      // cannot use reset because reset just set the value as undefined
      const dynamicFields = form.getFieldValue("dynamicFields");
      delete dynamicFields[item.id];
      form.setFieldsValue({ dynamicFields });

      delete tmpFields[item.fieldID];
      delete tmpChainFields[item.fieldID];
    });

    onChangeValue(fieldID, value);
    if (autoChain) {
      triggerChainFields(fieldID, value);
    } else {
      setFields({ ...tmpFields });
      setChainFields({
        ...tmpChainFields,
        [fieldID]: value,
      });
    }
  };

  const fieldComponent = generateField(onFetchValue, onChangeValue, form, ocrData);

  const parseAPIResponse = (res: any) => {
    return res?.data.payload as { key: string; fields: DynamicFormField[] };
  };

  const parseBulkAPIResponse = (res: any[]) => {
    return res?.map((item) => parseAPIResponse(item));
  };

  const setDefaultFormValue = (newFields: DynamicFormField[]) => {
    const newDynamicFields = {};
    const newDynamicFieldsByFieldID = {};
    newFields.forEach((item) => {
      const value = item.value || undefined; // to avoid empty string
      if (item.fieldProps.editable) {
        let currentValue = form.getFieldValue(["dynamicFields", item.id]) || value;
        if (item.alias === "beneficiaryName") {
          currentValue =
            form.getFieldValue(["dynamicFields", item.id]) || form.getFieldValue("beneficiaryName") || value;
        }
        newDynamicFields[item.id] = currentValue;
        newDynamicFieldsByFieldID[item.fieldID] = currentValue;
      } else {
        newDynamicFields[item.id] = value;
        newDynamicFieldsByFieldID[item.fieldID] = value;
      }
    });

    form.setFieldsValue({ dynamicFields: newDynamicFields, dynamicFieldsByFieldID: newDynamicFieldsByFieldID });
  };

  const handleMainFields = (res: any) => {
    const { key: _key, fields: newFields } = parseAPIResponse(res);
    setKey(_key);

    // hard code paymentMethod value from form-fields API
    newFields.forEach((item) => {
      // if it has paymentMethod & the recipientBank is paynow
      if (item.alias === "paymentMethod" && ocrData?.recipientBank?.toLowerCase()?.startsWith("paynow")) {
        item.value = "paynow";

        triggerChainFields(item.fieldID, item.value);
      }
      return item;
    });

    setFields(setAncestorIDs(newFields));

    // set default value from API to form
    setDefaultFormValue(newFields);

    const _chainFields = newFields.reduce((prev, curr) => {
      if (curr.fieldProps.doFetch) {
        const val = curr.value || form.getFieldValue(["dynamicFields", curr.id]) || "";
        return { ...prev, [curr.fieldID]: val };
      }
      return prev;
    }, {});

    if (Object.keys(_chainFields).length) {
      // keep loading to be true to give better experience
      setChainFields(_chainFields);
    }
  };

  useLayoutEffect(() => {
    if (!currency || !destinationCountry) {
      return;
    }

    const params = {
      currency,
      destinationCountry,
      combinationIDs,
    };

    if (source) {
      params["source"] = source;
    }

    if (billID) {
      params["billID"] = billID;
    }

    if (vendorId && combinationIDs.length) {
      params["vendorID"] = vendorId;
    }

    let cancel: Canceler;
    _fetchDynamicFields(params, {
      cancelToken: new axios.CancelToken((canceler) => {
        cancel = canceler;
      }),
    })
      .then(handleMainFields)
      .catch((err: Error) => {
        if (!axios.isCancel(err)) {
          console.error(err.message);
        }
      });

    return () => {
      cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, destinationCountry, combinationIDs]);

  const handleChainFields = (fieldsArr: DynamicFormField[][]) => {
    if (!fieldsArr.length) {
      return;
    }

    // merge all array from Promise.all
    const newFields = fieldsArr.reduce((prev, curr) => {
      if (curr?.length) {
        return [...prev, ...curr];
      }
      return prev;
    }, []);

    // set default value from API to form
    setDefaultFormValue(newFields);

    // remove if exist on fields
    setFields((prev) => ({ ...prev, ...setAncestorIDs([...Object.values(prev), ...newFields]) }));
  };

  const handleFieldValue = (value: unknown) => {
    const isCheck = typeof value === "boolean";
    if (isCheck) {
      return value ? "1" : "0";
    }
    return String(value);
  };

  const preventFetchChainFields = (fieldID: string, fieldValue: unknown) => {
    if (isNewDataFields[fieldID]) {
      return false;
    }
    if (!fieldValue) {
      return true;
    }
    return false;
  };

  const triggerChainFields = (fieldID?: number, fieldValue?: unknown) => {
    let cancelers: Canceler[] = [];
    let chains = Boolean(fieldID) && Boolean(fieldValue) ? { [fieldID]: fieldValue } : chainFields;
    const fetcher = Object.entries(chains).reduce((prev, curr, index) => {
      const [fieldID, fieldValue] = curr;
      if (preventFetchChainFields(fieldID, fieldValue)) {
        return prev;
      }

      let isNewData = Boolean(isNewDataFields[fieldID]);
      // Note: Set isNewData value as true, if user trying to create bill with new bank which is not exist in database
      const { options, fieldID: id } = fieldList?.find((field) => field.alias === "beneficiaryBankName") || {};
      // get value if fieldId is exists in chainFields
      const bankValue = chainFields?.[id];
      if (bankValue) {
        // check bank value is exist in database banks
        const isExistInDb = options?.some((field) => field.value === bankValue);
        isNewData = isNewData || !isExistInDb;
      }

      const params = {
        currency,
        destinationCountry,
        fieldID: Number(fieldID),
        fieldValue: handleFieldValue(fieldValue),
        key,
        isNewData,
      };

      if (source) {
        params["source"] = source;
      }

      if (billID) {
        params["billID"] = billID;
      }

      return [
        ...prev,
        _fetchDynamicFields(params, {
          cancelToken: new axios.CancelToken((canceler) => {
            cancelers[index] = canceler;
          }),
        }),
      ];
    }, []);

    Promise.all(fetcher)
      .then(parseBulkAPIResponse)
      .then(mapByKey("fields"))
      .then(handleChainFields)
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err.message);
        }
      });

    return () => {
      cancelers.forEach((cancel) => {
        cancel();
      });
    };
  };

  useLayoutEffect(() => {
    if (!key) {
      return;
    }

    return triggerChainFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainFields, currency, destinationCountry, key, isNewDataFields]);

  const context = useMemo(
    () => ({
      currency,
      destinationCountry,
      fields,
      form,
      isNewDataFields,
      key,
      setChainFields,
      setFields,
      setIsNewDataFields,
      trackView,
      trackEdit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currency,
      destinationCountry,
      fields,
      form,
      isNewDataFields,
      key,
      setChainFields,
      setFields,
      setIsNewDataFields,
      trackView,
      trackEdit,
    ]
  );

  const renderFields = () => {
    const DEFAULT_SECTION_HEADER = "Recipient Information";
    let lastSection = "";
    return sortByOrderNumber(fieldList).map((field) => {
      const sectionHeader = field?.fieldProps?.sectionHeader;
      let sectionHeaderComp;
      if (sectionHeader !== lastSection && sectionHeader !== DEFAULT_SECTION_HEADER) {
        lastSection = sectionHeader;
        sectionHeaderComp = <h3 className={styles.sectionHeader}>{sectionHeader}</h3>;
      }
      return (
        <Fragment key={field.id}>
          {sectionHeaderComp}
          {fieldComponent(field)}
        </Fragment>
      );
    });
  };

  return (
    <DynamicFormContext.Provider value={context}>
      <div className={styles.dynamicForm}>
        {renderFields()}
        {isLoading && <LoaderIcon />}
      </div>
    </DynamicFormContext.Provider>
  );
};

export default DynamicForm;
