import * as React from "react";
import { useSelector } from "react-redux";

import { trackEvent } from "utility/analytics";
import { EmployeeDeleteEventName } from "Views/Employee/Analytics";

import { IDeleteEmployeeCheckData } from "Redux/Actions/Employee/Delete/Check/types";

import { TEAMS_PATH_WITH_QUERY, TEAMS_URL_PARAMS } from "constants/Routes.constants";

import { IRequirement, IPending } from "Views/Employee/types";

import { getListItems, Requests, SoleTeamManager, ToApprove } from "Views/Employee/Delete/Modal/Resolve/Items";

const usePendingItems = (): IRequirement<IPending>[] => {
  const dataFromCheck: IDeleteEmployeeCheckData = useSelector((state: any) => state.deleteEmployeeCheckReducer.data);

  const {
    is_has_ongoing_transactions: transactionInProgress,
    pending_to_approve: pendingToApprove,
    pending_requests: pendingRequests,
    sole_team_managers: soleManager,
    id,
    name,
  } = !!dataFromCheck && dataFromCheck;

  return [
    {
      id: "RPA-TEAM",
      enabled: !!soleManager && soleManager.length > 0,
      requirement: {
        className: "pending-action-item__team",
        title: "This employee is the only manager in their team(s)",
        description:
          "A team needs at least one manager. Please visit the Teams page and promote another manager in the following teams:",
        list: { data: getListItems(soleManager, SoleTeamManager), collapsed: true, unit: "teams" },
        button: {
          action: () => {
            trackEvent(EmployeeDeleteEventName.VISIT_TEAM_LIST_PAGE_FROM_ELIGIBILITY_FAIL_POPUP);
            window.open(
              TEAMS_PATH_WITH_QUERY[TEAMS_URL_PARAMS.SOLE_MANAGER](id.toString(), name),
              "_blank",
              "noopener,noreferrer"
            );
          },
          text: "Go to Teams page",
        },
      },
    },
    {
      id: "RPA-REQUESTS",
      enabled: !!pendingRequests && pendingRequests.length > 0,
      requirement: {
        className: "pending-action-item__approval",
        title: "This employee has pending item(s) waiting for others’ action(s)",
        description: (
          <>
            This employee is waiting for other’s action(s) on some of their item(s). Please inform the respective
            approver(s) to resolve the following item(s) on the <strong>Requests</strong> page and/or the
            <strong> Approvals</strong> page:
          </>
        ),
        list: { data: getListItems(pendingRequests, Requests) },
      },
    },
    {
      id: "RPA-TO-APPROVE",
      enabled: !!pendingToApprove && pendingToApprove.length > 0,
      requirement: {
        className: "pending-action-item__request",
        title: "This employee has pending item(s) that require their action(s)",
        description: (
          <>
            Please inform the employee to review the following item(s). Employee can see their pending requests on
            <strong> Requests</strong> page and their pending bills on <strong>Transactions</strong> page.
          </>
        ),
        list: { data: getListItems(pendingToApprove, ToApprove) },
      },
    },
    {
      id: "RPA-TRANSACTIONS",
      enabled: transactionInProgress,
      requirement: {
        className: "pending-action-item__transaction",
        title: "This employee has transaction(s) in progress",
        description:
          "Some transaction(s) may need more time to process than others. Please wait for all of this employee’s transaction(s) to complete, or contact support@spenmo.com for further assistance.",
      },
    },
  ].filter((item) => item.enabled);
};

export default usePendingItems;
