import React from "react";
import { Input as AntInput } from "antd";

import { InputProps } from "./type";

const Input = (props: InputProps) => {
  const { isTrimSpace, onChange } = props;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (isTrimSpace) {
      e.target.value = value?.replace?.(/\s/g, "");
    }

    onChange(e);
  };

  return <AntInput {...props} onChange={handleOnChange} />;
};

export default Input;
