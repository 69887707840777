import APIclient from "API/Client";
import { getTeamProfileData } from "Redux/Actions/Teams/teamProfileAction";

export const FETCH_MEMBERROLE_REQUEST = "FETCH_MEMBERROLE_REQUEST";
export const FETCH_MEMBERROLE_SUCCESS = "FETCH_MEMBERROLE_SUCCESS";
export const FETCH_MEMBERROLE_FAILURE = "FETCH_MEMBERROLE_FAILURE";
export const MEMBER_ROLE_RESET = "MEMBER_ROLE_RESET";

const fetchMemberRoleReq = () => {
  return {
    type: FETCH_MEMBERROLE_REQUEST,
    data: {},
  };
};
const fetchMemberRoleSuccess = (data) => {
  return {
    type: FETCH_MEMBERROLE_SUCCESS,
    data: data,
  };
};
const fetchMemberRoleError = (data) => {
  return {
    type: FETCH_MEMBERROLE_FAILURE,
    data: data,
  };
};

export const resetMemberRole = () => {
  return {
    type: MEMBER_ROLE_RESET,
  };
};

export const setMemberRoleFunc = (data, teamId) => {
  return (dispatch) => {
    dispatch(fetchMemberRoleReq());
    APIclient.postData(`/ms/spm-organisation/v1/mgmt/budgets/${teamId}/members/permissions`, {
      budgetMembers: data,
    })
      .then((data) => {
        dispatch(fetchMemberRoleSuccess(data.data));
      })
      .then(() => {
        dispatch(getTeamProfileData(teamId));
      })
      .catch((err) => {
        dispatch(fetchMemberRoleError(err));
      });
  };
};
