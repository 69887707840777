import React, { useContext, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useFormContext } from "react-hook-form";
import { Button, ChevronDown } from "@spenmo/splice";
import qs from "query-string";
import { useLocation, useParams } from "react-router-dom";

import FormFooter from "Views/Bills/V2/BillForm/FormFooter";
import CustomWrapper from "Views/Bills/V2/BillForm/CustomWrapper";
import FieldController from "Views/Bills/V2/components/FieldController";

import { useBillDetailFields } from "Views/Bills/V2/hooks/useBillFormFields";

import { useBillForm } from "Views/Bills/V2/context/FormContext";
import { useErrorHandler } from "Views/Bills/V2/context/ErrorHandlerContext";
import { BillContext } from "Views/Bills/V2/context/BillContext";

import { generateBillDetailFields } from "./helper";
import { billSource } from "Views/Bills/V2/BillForm/helper";
import { API_URL, BillParams } from "Views/Bills/V2/constants";
import { GetCurrencyCode, GetOrgCountryCode } from "utility";
import { trackEvent } from "utility/analytics";
import { BillDetailFormProps, BillFlowEnum, BillFormFields, BillFormProviderProps } from "Views/Bills/V2/BillForm/type";
import styles from "./BillDetail.module.scss";
import useIsBulkView from "Views/Bills/V2/hooks/useIsBulkView";
import { BILL_EVENTS } from "Views/Bills/events";

const BillDetail = (props: BillDetailFormProps) => {
  const { onBack, onNext, isShowForm, onShowForm, billFlow } = props;
  const params = useParams<BillParams>();
  const location = useLocation();
  const vendorId = location.pathname.includes("/bills/recipients") && params.vendorId;
  const isBulkView = useIsBulkView();
  const {
    trigger,
    watch,
    formState: { isValid },
  } = useFormContext();

  const [initialSubmit, setInitialSubmit] = useState(true);

  const { handleSaveDraft, refetchValues, isSubmitLoading } = useBillForm<BillFormProviderProps>();
  const { ocrData } = useContext(BillContext);
  const { verifyErrorHandler } = useErrorHandler();

  useEffect(() => {
    if (billFlow === BillFlowEnum.NEW_BILL || billFlow === BillFlowEnum.EDIT_DRAFT) {
      trackEvent("Bill Creation Details Loaded", {
        bill_flow_type: billFlow,
      });
    } else {
      trackEvent(BILL_EVENTS.editPageLoaded, { bill_id: params.id });
    }
  }, []);

  const refetchKeys = Object.keys(refetchValues);
  const refetchVals = watch(refetchKeys);
  const ocrDataKey = useMemo(() => {
    return ocrData?.billData ? Object.keys(ocrData.billData) : [];
  }, [ocrData?.billData]);

  const ocrRelatedFields = watch(ocrDataKey);

  const refetchParams = useMemo(() => {
    const params = {};

    refetchKeys.forEach((key, index) => {
      params[key] = refetchVals[index];
    });

    return params;
  }, [refetchKeys, refetchVals]);

  const billDetailFieldsURL = qs.stringifyUrl({
    url: API_URL.billDetailFields,
    query: {
      ...refetchParams,
      step: 1,
      billID: params.id,
      source: billSource[billFlow],
      ...(vendorId ? { vendorID: vendorId } : {}),
      // IMPROVEMENT: when it's handled by BE
      // it's better to just send the orgID, and
      // let the BE get the countryCode on their own
      countryCode: GetOrgCountryCode(),
      dashboardCurrency: GetCurrencyCode(),
    },
  });

  useEffect(() => {
    if (vendorId) {
      onShowForm();
    }
  }, [vendorId, onShowForm]);

  // IMPROVEMENT: on changing to useSWR (get data from API)
  // please make the url to function that return the url
  // Ref: https://swr.vercel.app/docs/conditional-fetching
  const { data: BillDetailField } = useBillDetailFields(billDetailFieldsURL, {
    keepPreviousData: true,
  });

  const handleClickNext = () => {
    if (!verifyErrorHandler()) {
      return;
    }
    trigger().then((isValid) => {
      if (isValid) {
        onNext();
      } else {
        setInitialSubmit(false);
      }
    });
  };

  const formFields = useMemo(() => {
    const formFields = {} as BillFormFields;
    ocrDataKey.forEach((key, index) => {
      formFields[key] = ocrRelatedFields[index];
    });
    return generateBillDetailFields(BillDetailField?.data?.payload?.fields, ocrData, formFields);
  }, [BillDetailField?.data?.payload?.fields, ocrData, ocrDataKey, ocrRelatedFields]);

  return (
    <div className={styles.billDetails}>
      {!isShowForm ? (
        <div className={cn(styles.formToggle)} onClick={onShowForm} role="button">
          <span>Or fill details manually</span>
          <ChevronDown className={styles.chevron} size="16" iconColor="#545454" />
        </div>
      ) : (
        <div className={styles.fadeIn}>
          {formFields.map((item) => (
            <FieldController key={item.name} {...item}>
              {(props, Component) => {
                return (
                  <CustomWrapper {...props}>
                    {(fieldProps: Record<string, any>) => <Component {...fieldProps} />}
                  </CustomWrapper>
                );
              }}
            </FieldController>
          ))}
          <FormFooter
            onClickBack={onBack}
            onSubmit={handleClickNext}
            submitText="Next"
            disableSubmit={!initialSubmit && !isValid}
            isSubmitLoading={isSubmitLoading}
          >
            {billFlow !== BillFlowEnum.EDIT_BILL && (
              <Button type="button" size="m" variant="secondary" onClick={handleSaveDraft} loading={isSubmitLoading}>
                {isBulkView ? "Save changes" : "Save as draft"}
              </Button>
            )}
          </FormFooter>
        </div>
      )}
    </div>
  );
};

export default BillDetail;
