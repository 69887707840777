import React, {
  useRef,
  MouseEvent,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react";
import cn from "classnames";
import qs from "query-string";
import { Upload } from "antd";
import {
  RcCustomRequestOptions,
  RcFile,
  UploadFile,
} from "antd/lib/upload/interface";
import {
  CrossFilled,
  InfoOutline,
  UploadOutline,
  DeleteOutline,
  Typography,
  Tooltip,
  Loader,
} from "@spenmo/splice";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import APIClient from "API/Client";
import { useMutableData } from "API/useData";
import PDFViewer from "Modules/PDFViewer/PDFViewer";
import FormController from "Views/Bills/V2/components/FormController";

import { useToaster } from "Views/Bills/V2/hooks";
import { TABS } from "Views/Bills/const";
import { getAcceptedFiles, isPDF } from "Views/Bills/V2/utilities";
import { trackEvent } from "utility/analytics";
import { uploadInvoice } from "assets/img";
import { BillContext } from "Views/Bills/V2/context/BillContext";
import { useTransformHeicImage } from "Views/Bills/V2/hooks/useTransformHeicImage";
import { mapOCRData, transformOCRResp } from "./helper";
import { IGNORE_OCR_FIELDS } from "./constants";

import {
  ALLOWED_FILE_EXTENSIONS,
  ALLOWED_FILE_EXTENSIONS_FORMATTED,
  API_URL,
  BillFormType,
  BillParams,
  MAX_ALLOWED_FILE_UPLOAD,
  MAX_FILE_SIZE_ALLOWED,
  MAX_FILE_SIZE_ALLOWED_IN_MB,
} from "Views/Bills/V2/constants";

import styles from "./Uploader.module.scss";

interface UploaderProps {
  onHandleUpload(status: "uploading" | "done"): void;
  showField?: boolean;
}

interface FileItemProps {
  file: UploadFile;
  onDelete(): void;
}

interface ThumbnailProps {
  file: UploadFile;
  width?: number;
  height?: number;
  loaderSize?: "s" | "l";
  showNav?: boolean;
}

interface ErrorUploadProps {
  name: string;
  width?: number;
  height?: number;
}

const DEFAULT_ERROR_UPLOAD_MESSAGE = "Unable to upload attachments.";

const FilePlaceholder = (props: ErrorUploadProps) => {
  const { name, width = 32, height = 32 } = props;

  return (
    <img
      className={styles.thumbnail}
      src={uploadInvoice}
      alt={name}
      width={width}
      height={height}
    />
  );
};

const Thumbnail = (props: ThumbnailProps) => {
  const {
    file,
    width = 32,
    height = 32,
    loaderSize = "l",
    showNav = false,
  } = props;
  const { status, response, name, type } = file;
  const { fileUrl } = response || {};

  const { imageUrl, isLoading: transformLoading } = useTransformHeicImage(
    type,
    fileUrl,
  );

  const isDone = status === "done";

  if (!isDone) {
    return <FilePlaceholder name={name} width={width} height={height} />;
  }

  if (transformLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader.Spinner
          size={loaderSize}
          variant="brand"
          className={styles.thumnailLoader}
        />
      </div>
    );
  }

  if (isPDF(fileUrl)) {
    return <PDFViewer showArrows={showNav} file={fileUrl} />;
  }

  return (
    <img
      className={styles.thumbnail}
      src={imageUrl}
      alt={name}
      width={width}
      height={height}
    />
  );
};

const FileItem = (props: FileItemProps) => {
  const { file, onDelete } = props;
  const { response, name } = file;
  const { fileUrl } = response || {};

  const handleClickPreview = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(fileUrl, "__blank");
  };

  return (
    <div className={styles.previewItem}>
      <a
        className={styles.link}
        rel="noopener noreferrer"
        href={fileUrl}
        target="_blank"
        onClick={handleClickPreview}
      >
        <div className={styles.imgContainer}>
          <Thumbnail file={file} loaderSize="s" />
        </div>
        <Typography
          className={styles.fileName}
          variant="body-content"
          tag="p"
          size="s"
        >
          {name}
        </Typography>
      </a>
      <CrossFilled
        className={styles.closeIcon}
        iconColor="var(--icon-strong)"
        size="16"
        onClick={onDelete}
      />
    </div>
  );
};

const Uploader: React.FC<UploaderProps> = (props) => {
  const { onHandleUpload, showField } = props;
  const history = useHistory();
  const location = useLocation();
  const params = useParams<BillParams>();

  const isNewBill = useMemo(() => params.form === BillFormType.NEW, [params]);
  const isEditForm = useMemo(
    () => params.form === BillFormType.EDIT && params.id.length > 0,
    [params],
  );
  const isEditDraft = useMemo(
    () => isEditForm && location.pathname.includes("/bills/drafts"),
    [isEditForm, location.pathname],
  );

  const { setValue, control, getFieldState, watch } = useFormContext();
  const { setOCRData, setRecipientSelectedID } = useContext(BillContext);

  const deletedAttachmentIDs = watch("deletedAttachmentIDs", []);
  const { appNotification } = useToaster();

  const isMultiUploaded = useRef(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const billDetailURL = qs.stringifyUrl({
    url: `${API_URL.disbursementV1}/bill/${params.id}`,
    query: {
      source: isEditDraft && !isNewBill ? "edit_draft" : "edit_bill",
    },
  });

  const { data: billDetailData } = useMutableData(
    params.id ? billDetailURL : null,
    {
      onSuccess: (res) => {
        const transformedData = transformOCRResp(res?.data?.payload);

        if (transformedData) {
          setOCRData(mapOCRData(transformedData));
        }
      },
    },
  );

  const ocrAttachment = billDetailData?.data?.payload?.ocrAttachment || {};

  const uploadedAttachment = useMemo(() => {
    if (ocrAttachment?.id && !deletedAttachmentIDs.includes(ocrAttachment.id)) {
      let type = ocrAttachment.filename.split(".").pop().toLowerCase();

      switch (type) {
        case "pdf": {
          type = "application/pdf";
          break;
        }
        default: {
          type = `image/${type}`;
        }
      }

      return {
        uid: ocrAttachment.id,
        name: ocrAttachment.filename,
        response: {
          fileUrl: ocrAttachment.url,
        },
        size: ocrAttachment.size,
        type: type,
        status: "done" as "done",
      };
    }

    return null;
  }, [ocrAttachment, deletedAttachmentIDs]);

  const validateFile = (file: RcFile) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const extensionValid = ALLOWED_FILE_EXTENSIONS.includes(fileExtension);
    const sizeValid = file.size <= MAX_FILE_SIZE_ALLOWED;
    const isFileValid = extensionValid && sizeValid;

    const allowedExtensionText = ALLOWED_FILE_EXTENSIONS.reduce(
      (prev, curr) => {
        if (
          curr === ALLOWED_FILE_EXTENSIONS[ALLOWED_FILE_EXTENSIONS.length - 1]
        ) {
          return prev + curr;
        }
        return (
          prev +
          curr +
          `, ${curr === ALLOWED_FILE_EXTENSIONS[ALLOWED_FILE_EXTENSIONS.length - 2] ? "and " : ""}`
        );
      },
      "",
    );

    if (!extensionValid) {
      appNotification.error({
        key: "allowed-file-extension",
        message: `Bill cannot be processed due to unsupported format. We support ${allowedExtensionText} formats.`,
      });
    } else if (!sizeValid) {
      appNotification.error({
        key: "max-file-size",
        message: `Bill exceeded the maximum file size of ${MAX_FILE_SIZE_ALLOWED_IN_MB} MB`,
      });
    }

    return isFileValid;
  };

  const handleMultipleUpload = (fileList: RcFile[]) => {
    if (isMultiUploaded.current) {
      return;
    }

    if (fileList.length > MAX_ALLOWED_FILE_UPLOAD) {
      appNotification.error({
        key: "max-file-upload",
        message: "Can't upload more than 30 files",
      });
      return;
    }

    const formData = new FormData();

    const validatedFiles = fileList.filter((file) => {
      const isValid = validateFile(file);
      if (isValid) {
        formData.append("files", file);
      }
      return isValid;
    });

    if (!validatedFiles.length) {
      return;
    }

    isMultiUploaded.current = true;

    history.push(TABS.MANAGE_DRAFT.link, {
      ...(location.state as object),
      isUploading: true,
    });

    APIClient.postData(API_URL.draftUpload, formData).then(() => {
      appNotification.success({
        message: `${validatedFiles.length} of ${fileList.length} attachments are being uploaded.`,
      });
      history.push(TABS.MANAGE_DRAFT.link, {
        ...(location.state as object),
        isUploading: false,
      });
    });
  };

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]): boolean => {
    // check multiple uploads
    if (fileList.length > 1) {
      handleMultipleUpload(fileList);
      return false;
    }

    if (!validateFile(file)) {
      return false;
    }

    // single file
    return fileList.length === 1;
  };

  const handleRequest = async (options: RcCustomRequestOptions) => {
    const { file, onProgress, onSuccess, onError } = options;
    const formData = new FormData();
    formData.append("file", file);

    APIClient.postData(API_URL.extractOCR, formData, false, {
      onUploadProgress: onProgress,
      timeout: 30000, // 30s
    })
      .then((res) => {
        const { payload: extractPayload, error } = res.data;

        if (error) {
          appNotification.error({
            errorCode: error.code,
            message: error.message,
          });
          return;
        }
        onSuccess(extractPayload, file);
        trackEvent("bill upload attachment success");
      })
      .catch((err) => {
        console.error(err);
        onError(err);

        if (err?.response?.data?.error) {
          const { code, message } = err.response.data.error;

          if (message) {
            appNotification.error({
              errorCode: code,
              message,
            });

            return;
          }
        }

        appNotification.error({ message: DEFAULT_ERROR_UPLOAD_MESSAGE });
      });
  };

  const handleDeleteUploadedFile = (fileID: string) => {
    setValue("deletedAttachmentIDs", [fileID, ...deletedAttachmentIDs]);
  };

  const handleDeleteFile = () => {
    setFileList([]);
  };

  const handleUpdateFile = () => {
    const uploader = document.getElementById("ocrUpload");

    if (uploader) {
      uploader.click();
    }
  };

  // single file
  const handleChangeFile = ({ file }, callbackFn) => {
    /* only setFileList on uploading / done.
    doing [file] since we only allowed a single file
    but the function accept array of files
    */
    setFileList(getAcceptedFiles([file]));
    if (uploadedAttachment) {
      handleDeleteUploadedFile(uploadedAttachment.uid);
    }

    onHandleUpload(file.status);

    if (file.status === "done") {
      const { response } = file;
      const mappedOCRData = mapOCRData(response);
      const { billData } = mappedOCRData;

      setOCRData(mappedOCRData);

      if (billData.vendorID) setRecipientSelectedID(billData.vendorID);

      // call Form hooks onChange
      callbackFn(billData.ocrID);

      // set bill form value from OCR
      Object.entries(billData).forEach(([key, value]) => {
        const { isDirty } = getFieldState(key);

        if (!isDirty && !IGNORE_OCR_FIELDS[key]) {
          setValue(key, value);
        }
      });
    }
  };

  const ocrFile = fileList?.[0] || uploadedAttachment;

  const { status, response } = ocrFile || {};
  const { fileUrl } = response || {};

  const isShowField =
    showField && (isNewBill || (!isNewBill && uploadedAttachment));

  return (
    <div
      className={cn(styles.uploader, {
        [styles.separator]: isShowField,
      })}
    >
      {isShowField && (
        <FormController
          name="ocrID"
          control={control}
          render={({ field }) => {
            const { onChange, value, ...rest } = field;
            const defaultFileList = value
              ? {
                  uid: "default-invoice-file",
                  name: value?.name,
                  size: value?.size,
                  type: value?.type,
                  status: "done" as "done",
                  url: value,
                }
              : undefined;

            return (
              <Upload.Dragger
                {...rest}
                id="ocrUpload"
                multiple
                accept={ALLOWED_FILE_EXTENSIONS_FORMATTED}
                defaultFileList={[defaultFileList]}
                fileList={fileList}
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                customRequest={handleRequest}
                disabled={!isNewBill}
                onChange={(e) => handleChangeFile(e, onChange)}
              >
                <div className={styles.uploaderArea}>
                  <UploadOutline
                    className={styles.uploadIcon}
                    iconColor="var(--icon-strong)"
                    size="24"
                  />
                  <div className={styles.uploadInfo}>
                    <Typography
                      className={styles.title}
                      variant="headline-content"
                      tag="h3"
                      size="m"
                    >
                      Upload bills
                    </Typography>
                    {fileList.length ? (
                      <div
                        className={styles.preview}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {fileList.map((file, index) => {
                          return (
                            <FileItem
                              key={`${file.name}-${index}`}
                              file={file}
                              onDelete={handleDeleteFile}
                            />
                          );
                        })}
                      </div>
                    ) : uploadedAttachment ? (
                      <div
                        className={styles.preview}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FileItem
                          file={uploadedAttachment}
                          onDelete={() =>
                            handleDeleteUploadedFile(uploadedAttachment.uid)
                          }
                        />
                      </div>
                    ) : (
                      <div className={styles.empty}>
                        <Typography variant="body-content" tag="p" size="s">
                          Drag and drop up to 30 bills.
                        </Typography>
                        <Tooltip
                          title="You can only upload one invoice file per bill. Any additional file can be uploaded under Attachment on payment details."
                          placement="top"
                        >
                          <InfoOutline
                            className={styles.infoIcon}
                            iconColor="var(--icon-default)"
                            size="16"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </Upload.Dragger>
            );
          }}
        />
      )}
      {status === "done" && (
        <div className={styles.invoicePreview}>
          <Typography
            className={styles.invoiceTitle}
            variant="headline-content"
            size="l"
            tag="h3"
          >
            Invoice
          </Typography>
          <div className={styles.invoice}>
            <a
              className={styles.link}
              rel="noopener noreferrer"
              href={fileUrl}
              target="_blank"
            >
              <Thumbnail
                showNav={true}
                file={ocrFile}
                width={665}
                height={900}
              />
            </a>
            <div className={styles.action}>
              {isNewBill && (
                <Typography
                  className={cn(styles.actionItem, styles.update)}
                  variant="body-content"
                  size="s"
                  tag="p"
                  onClick={handleUpdateFile}
                >
                  <UploadOutline
                    className={styles.actionIcon}
                    iconColor="#0c75d2"
                    size="16"
                  />
                  Change file
                </Typography>
              )}
              <Typography
                className={cn(styles.actionItem, styles.delete)}
                variant="body-content"
                size="s"
                tag="p"
                onClick={
                  uploadedAttachment
                    ? () => handleDeleteUploadedFile(uploadedAttachment.uid)
                    : handleDeleteFile
                }
              >
                <DeleteOutline
                  className={styles.actionIcon}
                  iconColor="#e41b1b"
                  size="16"
                />
                Delete
              </Typography>
            </div>
          </div>
        </div>
      )}

      {/*  TO DO: replace the size with the variable in the constants file */}
      {isShowField && (
        <div className={styles.hint}>
          Only PDF, PNG, HEIC, HEIF, and JPG formats are accepted. Max. file
          size : 10 MB per file
        </div>
      )}
    </div>
  );
};

export default Uploader;
